//dropdown
window.addEventListener("load", function(event) {
const dropdownButtons = document.getElementsByClassName('dropdown-btn');
if (dropdownButtons) {
  // alert(dropdownButtons.length);
  for (let i = 0; i < dropdownButtons.length; i++) {
    dropdownButtons[i].addEventListener('click', function (e) {
      // alert('1');
      const dropdown = this.parentElement;
      // alert(this.parentElement);
      // alert($(this).parent());
      // alert(this.elRef.nativeElement.parentElement);
      clickAway(dropdown);
			if (dropdown.classList.contains('isOpen')) {
        // alert('if');
				dropdown.classList.remove('isOpen');
			} else {
        // alert('else');
				dropdown.classList.add('isOpen');
			}
		});
	}
}

const clickAway = (element) => {
  // alert('if');
	document.addEventListener('mousedown', (event) => {
		if (!element.contains(event.target)) {
			//Clicked Outside / Elsewhere
			element.classList.remove('isOpen');
		}
	});
};

//burger menu

const lineUpper = document.getElementById('menu-line-upper');
const lineLower = document.getElementById('menu-line-lower');
const headerResponsive = document.getElementById('header-responsive');

function burgerMenuSwitch() {
	if (headerResponsive.classList.contains('header-responsive-open')) {
		closeMobileMenu();
	} else {
		openMobileMenu();
	}
}

function openMobileMenu() {
	lineUpper.className += ' line-upper-rotated';
	lineLower.className += ' line-lower-rotated';
	headerResponsive.className += ' header-responsive-open';
}

function closeMobileMenu() {
	lineUpper.classList.remove('line-upper-rotated');
	lineLower.classList.remove('line-lower-rotated');
	headerResponsive.classList.remove('header-responsive-open');
}

//search wrap
const searchWrapResponsive = document.getElementById('header-search');

function openSearchWrap() {
	searchWrapResponsive.className += ' search-responsive-wrap-open';
}

function closeSearchWrap() {
	searchWrapResponsive.classList.remove('search-responsive-wrap-open');
}

window.addEventListener(
	'resize',
	function (event) {
		if (window.screen.width > 1024) {
			closeMobileMenu();
			closeSearchWrap();
		}
	},
	true
);

function searchWrapSwitch() {
	if (searchWrapResponsive.classList.contains('search-responsive-wrap-open')) {
		closeSearchWrap();
	} else {
		openSearchWrap();
	}
}

//bot create dialog

const botCreateDialog = document.getElementById('bot-create-dialog');

function botCreateSwitch() {
	if (botCreateDialog.classList.contains('display-block')) {
		botCreateDialog.classList.remove('display-block');
	} else {
		botCreateDialog.className += ' display-block';
	}
}

//change status dialog

const changeStatusDialog = document.getElementById('status-change-dialog');
function changeStatusSwitch() {
	if (changeStatusDialog.classList.contains('display-block')) {
		changeStatusDialog.classList.remove('display-block');
	} else {
		changeStatusDialog.className += ' display-block';
	}
}
});
